<template>
  <v-card>
    <form method="post" @submit.prevent="submit">
      <v-toolbar
        color="#7070a0"
        flat
        dark
      >
        <v-toolbar-title>Sign up for free</v-toolbar-title>
      </v-toolbar>
      <v-alert v-if="error" type="error" class="ma-1">{{ error.message }}</v-alert>
      <v-card-text>
        <p>Welcome to <strong>Nuokka</strong>, the future of online marketplaces.
        Sign up for your completely free account below and get started selling
        or shopping&hellip;</p>

        <p>How will you be using <strong>Nuokka</strong>?</p>
        <v-radio-group v-model="accountType">
          <v-radio color="#7070a0" value="seller">
            <template v-slot:label>
              <span>I'm a merchant; I want to <strong>sell products/services</strong></span>
            </template>
          </v-radio>
          <v-radio color="#7070a0" value="buyer">
            <template v-slot:label>
              <span>I'm a customer; I only want to <strong>browse and purchase</strong></span>
            </template>
          </v-radio>
        </v-radio-group>
      </v-card-text>
      <v-card-title class="py-0">Account details</v-card-title>
      <v-card-text>
        <v-row align="center" dense>
          <v-col cols="12">
            <v-text-field
              label="Account name"
              :error-messages="accountErrors"
              v-model.trim="$v.account.$model"
              id="account"
              @keyup="filterAccount"
              @input="$v.account.$touch()"
              @blur="$v.account.$touch()"
              size="255"
              hint="How you want to be identified on Nuokka. Must only contain alphanumeric and hyphen chars. No spaces. Disallowed characters will be removed."
              persistent-hint
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Your name"
              autocomplete="name"
              :error-messages="requiredError($v.legalName, 'Your name')"
              v-model.trim="$v.legalName.$model"
              @input="$v.legalName.$touch()"
              @blur="$v.legalName.$touch()"
              size="255"
              hint="Must be full legal name of entity that owns the account. For example, “My Company Ltd” or “Joseph Bloggs”."
              persistent-hint
              required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-row align="center" dense>
          <v-col cols="6">
            <TerritorySelect
              label="Tax region"
              required
              v-model.trim="$v.taxRegion.$model"
              @input="$v.taxRegion.$touch()"
              @blur="$v.taxRegion.$touch()"
              :error-messages="requiredError($v.taxRegion, 'Tax region')"
            ></TerritorySelect>
          </v-col>
          <v-col cols="6">
            <CurrencySelect
              label="Default currency"
              required
              v-model.trim="$v.currency.$model"
              @input="$v.currency.$touch()"
              @blur="$v.currency.$touch()"
              :error-messages="requiredError($v.currency, 'Default currency')"
            ></CurrencySelect>
          </v-col>
        </v-row>
      </v-card-text>
      <div v-if="accountType == 'seller'">
        <v-card-title class="py-0">Business Address</v-card-title>
        <v-card-text>
          <div class="d-flex align-start mt-1">
            <v-icon color="#7070a0" left>{{ icon.mdiHelpCircle }}</v-icon>
            <p class="caption grey--text text--darken-2">As you are signing up
              as a seller, we require at least one business address in order to
              ensure we know where you are, and how local you are to customers.
              This will be your default address, initially. You can change this,
              and add additional addresses via your Nuokka address book after
              sign-up.
            </p>
          </div>
          <AddressEdit v-model="address" default />
        </v-card-text>
      </div>
      <v-card-title class="py-0">Login details</v-card-title>
      <v-card-text>
        <v-checkbox
          color="#7070a0"
          v-model="nameDisabled"
          label="My full name is the same as the account legal name"
        ></v-checkbox>
        <v-text-field
          label="Full name"
          autocomplete="name"
          :disabled="nameDisabled"
          :error-messages="requiredError($v.fullName, 'Full name')"
          v-model.trim="$v.fullName.$model"
          @input="$v.fullName.$touch()"
          @blur="$v.fullName.$touch()"
          size="255"
          hint="The full name of the person associated with this login."
          persistent-hint
        ></v-text-field>
        <v-text-field
          label="Login email"
          hint="This will be used as your login username and should be your personal contact address."
          persistent-hint
          autocomplete="email"
          :error-messages="emailErrors($v.email)"
          v-model.trim="$v.email.$model"
          id="email"
          @keyup="lower"
          @input="$v.email.$touch()"
          @blur="$v.email.$touch()"
          type="email"
          size="255"
          required
        ></v-text-field>
        <v-text-field
          label="Password"
          autocomplete="off"
          :error-messages="passwordErrors"
          v-model="$v.password.$model"
          @input="$v.password.$touch()"
          @blur="$v.password.$touch()"
          :append-icon="showPass ? icon.mdiEye : icon.mdiEyeOff"
          :type="showPass ? 'text' : 'password'"
          @click:append="showPass = !showPass"
          size="255"
          required
        ></v-text-field>
        <password
          v-model="$v.password.$model"
          :error-messages="passwordErrors"
          :strength-meter-only="true"
          @score="score => passwordScore = score"
        />
      </v-card-text>
      <StripeNotice v-if="accountType == 'seller'" />
      <v-card-text>
        <v-checkbox
          color="#7070a0"
          v-model="acceptTerms"
        >
          <template v-slot:label>
            <div>
              I confirm that I have read and agree to the Nuokka
              <router-link
                :to="{'name': 'legal-terms'}"
              >terms of service</router-link>
              and
              <router-link
                :to="{'name': 'legal-privacy'}"
              >privacy policy</router-link>.
            </div>
          </template>
        </v-checkbox>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn
          :loading="loading"
          color="#7070a0"
          type="submit"
          depressed
          dark
        >Sign me up!</v-btn>
      </v-card-actions>
    </form>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Password from 'vue-password-strength-meter'
  import AddressEdit from '@/components/AddressEdit'
  import CurrencySelect from '@/components/CurrencySelect'
  import TerritorySelect from '@/components/TerritorySelect'
  import StripeNotice from '@/components/StripeNotice'
  import { validationMixin } from 'vuelidate'
  import { required, minLength, email, requiredUnless, helpers } from 'vuelidate/lib/validators'
  import fb from '@/firebase'
  import { mdiEye, mdiEyeOff, mdiHelpCircle } from '@mdi/js'

  export default {
    mixins: [ validationMixin ],
    components: {
      Password,
      AddressEdit,
      CurrencySelect,
      TerritorySelect,
      StripeNotice
    },
    data() {
      return {
        icon: { mdiEye, mdiEyeOff, mdiHelpCircle },
        showPass: false,
        accountType: 'seller',
        nameDisabled: true,
        account: '',
        legalName: '',
        taxRegion: null,
        currency: null,
        address: null,
        email: '',
        password: '',
        passwordScore: 0,
        acceptTerms: false
      }
    },
    mounted() {
      this.$store.commit('setStateToken')
    },
    validations: {
      account: {
        required,
        minLength: minLength(4),
        notBlacklisted (value) {
          return Boolean(
            !helpers.req(value) ||
            ![
              ...this.accountBlacklist,
              ...this.wordBlacklist
            ].includes(value))
        },
        isAccountUnique: value =>
          value === '' ||
          value.length < 4 ||
          fb.db.collection('accounts')
            .doc(value)
            .get()
            .then(documentSnapshot => !documentSnapshot.exists)
            .catch(error => this.logError('isAccountUnique error', { error }))
      },
      legalName: { required },
      taxRegion: { required },
      currency: { required },
      fullName: { required: requiredUnless('nameDisabled') },
      email: {
        required,
        email,
        isEmailUnique (value) {
          if (value === '') return true

          return fb.db.collection('users')
            .where('email', '==', value).get()
            .then(querySnapshot => {
              return !!querySnapshot.empty
            })
        }
      },
      password: {
        required,
        isStrongEnough () {
          return this.passwordScore > 2
        }
      }
    },
    computed: {
      ...mapGetters([
        "accountBlacklist",
        "wordBlacklist"
      ]),
      accountErrors () {
        const errors = [],
              field = this.$v.account
        if (!field.$dirty) return errors
        !field.required && errors.push('Account is required')
        !field.notBlacklisted && errors.push('Account must not be blacklisted')
        !field.minLength && errors.push(`Account must be ${field.$params.minLength.min} characters long or more`)
        !field.isAccountUnique && errors.push('This account name already exists. Try adding some extra characters.')
        return errors
      },
      passwordErrors () {
        const errors = [],
              field = this.$v.password
        if (!field.$dirty) return errors
        !field.required && errors.push('Password is required')
        !field.isStrongEnough && errors.push('Password must be stronger')
        return errors
      },
      error () {
        return this.$store.getters.error
      },
      loading () {
        return this.$store.getters.loading
      },
      isMerchant() {
        return this.accountType === 'seller'
      },
      stripeURI() {
        const returnURI = this.getFQUri('/stripe/connect')
        return `https://connect.stripe.com/oauth/authorize?response_type=code&scope=read_write&redirect_uri=${returnURI}&client_id=${this.$config.stripe.clientId}&state=${this.$store.getters.stateToken}`
      }
    },
    watch: {
      legalName(value) {
        if (this.nameDisabled) {
          this.fullName = value
        }

        if (this.address) {
          this.$set(this.address, 'contact', value)
        } else {
          this.address = {
            contact: value
          }
        }
      },
      taxRegion(value) {
        if (this.address) {
          this.$set(this.address, 'country', value)
        } else {
          this.address = {
            country: value
          }
        }
      },
      nameDisabled(value) {
        if (value) {
          this.fullName = this.legalName
        }
      }
    },
    methods: {
      remove (tag, tags) {
        tags.splice(tags.indexOf(tag), 1)
        tags = [...tags]
      },
      lower (e) {
        this[e.target.id] = this[e.target.id].toLowerCase()
      },
      filterAccount(e) {
        this.lower(e)
        this[e.target.id] = this[e.target.id].replace(/[^a-z0-9-]/ig, '')
      },
      requiredError(field, label) {
        return field.$dirty && !field.required ? label + ' is required' : ''
      },
      emailErrors (field) {
        const errors = []
        if (!field.$dirty) return errors
        !field.required && errors.push('Email is required')
        !field.email && errors.push('Must be a valid email address')
        if (Object.keys(field.$params).includes('isEmailUnique')) {
          !field.isEmailUnique && errors.push('Email address already in use')
        }
        return errors
      },
      getFQUri(path) {
        const loc = window.location
        return loc.protocol + '//' + loc.host + path
      },
      submit () {
        this.$v.$touch()
        this.showPass = false
        if (this.acceptTerms && !this.$v.$invalid) {
          this.$store.dispatch('clearError')
          this.$store.dispatch('setAppLoading', true)
          this.$store.dispatch('setLoading', true)
          this.$store.dispatch('signUserUp', {
            account: this.account,
            type: this.accountType,
            legalName: this.legalName,
            taxRegion: this.taxRegion,
            currency: this.currency,
            fullName: this.nameDisabled ? this.legalName : this.fullName,
            email: this.email.toLowerCase(),
            password: this.password
          })
            .then(() => {
              this.logInfo('Completed sign-up')
              if (this.accountType === 'buyer') {
                this.$store.dispatch('setAppLoading', false)
                this.$store.dispatch('setLoading', false)
                this.$router.push({name: 'stream'}, () => {})
              } else {
                // Auto-signin will set AppLoading to false, so we force it
                // back to true here for good measure.
                this.$store.dispatch('setAppLoading', true)
                this.$store.dispatch('setLoading', true)
                return this.$store.dispatch('createAddress', {
                  ...this.address,
                  isDefault: true
                })
                  .then(() => window.location.href = this.stripeURI)
              }
            })
            .catch(error => {
              this.$store.dispatch('setLoading', false)
              this.$store.dispatch('setError', true)
              this.logError('Error during sign-up', {error: error.message})
            })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .Password {
    max-width: none !important;
  }
</style>
